<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="d-flex align-center flex-column pointer mb-12"
        @click="handleLocalImages()"
      >
        <div class="d-flex align-center">
          <v-avatar color="foreground" size="5rem" rounded>
            <v-img
              v-if="images.length"
              :src="images[0].objectURL || $store.getters.apiURL + images[0]"
            />

            <v-icon v-else color="grey" x-large>
              mdi-image-plus-outline
            </v-icon>
          </v-avatar>

          <v-avatar
            v-if="localImages.length > 1"
            class="ml-2"
            size="2rem"
            color="foreground"
            rounded
          >
            <span class="text-body-2 font-weight-bold grey--text">
              +{{ localImages.length - 1 }}
            </span>
          </v-avatar>
        </div>

        <v-btn class="rounded-lg text-none mt-4" color="primary" depressed>
          Gerenciar Imagens
        </v-btn>
      </div>
    </template>

    <v-card class="pa-4">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">Gerenciar imagens do produto</div>

        <v-spacer />

        <v-btn color="primary" small icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div
        v-for="(item, i) in localImages"
        class="d-flex align-center justify-space-between rounded-lg foreground mb-2 py-2 px-4"
        :key="i"
      >
        <div class="d-flex flex-column align-center mr-6">
          <v-btn
            class="rounded-lg"
            :disabled="i === 0"
            small
            icon
            @click="moveUp(i)"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>

          <div class="text-body-1 font-weight-bold my-1">{{ i + 1 }}º</div>

          <v-btn
            class="rounded-lg"
            :disabled="i === localImages.length - 1"
            small
            icon
            @click="moveDown(i)"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </div>

        <v-img
          :src="item.objectURL || $store.getters.apiURL + item"
          max-width="15rem"
          :aspect-ratio="16 / 9"
          contain
        />

        <v-btn
          class="rounded-lg ml-6"
          outlined
          small
          icon
          @click="removeImage(i)"
        >
          <v-icon small>mdi-delete-outline</v-icon>
        </v-btn>
      </div>

      <div class="mt-4 mb-10 d-flex algin-center justify-center">
        <FileInput
          v-model="newImages"
          ref="addImage"
          :accept="'image/jpeg,image/jpg,image/png'"
          :multiple="true"
          @input="handleMergeImages($event)"
        >
          <v-btn
            class="rounded-lg text-none"
            color="primary"
            outlined
            @click="handleFileinput()"
          >
            <v-icon left>mdi-plus</v-icon>

            Adicionar Mais
          </v-btn>
        </FileInput>
      </div>

      <div class="d-flex">
        <v-spacer />

        <v-btn class="text-none rounded-lg" plain @click="close()">
          Cancelar
        </v-btn>

        <v-btn
          class="text-none rounded-lg"
          color="primary"
          depressed
          @click="submit()"
        >
          Confirmar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import FileInput from "@/components/fileInput/FileInput";

export default {
  name: "ManageImages",

  components: {
    FileInput,
  },

  data() {
    return {
      dialog: false,
      localImages: [],
      newImages: [],
    };
  },

  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    submit() {
      this.$emit("submit", this.localImages);
      this.close();
    },

    moveUp(index) {
      if (index > 0) {
        const temp = this.localImages[index];

        this.localImages.splice(index, 1);
        this.localImages.splice(index - 1, 0, temp);
      }
    },

    moveDown(index) {
      if (index < this.localImages.length - 1) {
        const temp = this.localImages[index];

        this.localImages.splice(index, 1);
        this.localImages.splice(index + 1, 0, temp);
      }
    },

    removeImage(index) {
      this.localImages.splice(index, 1);
    },

    handleMergeImages(event) {
      if (this.localImages.length)
        this.localImages = [...this.localImages, ...event];
      else this.localImages = structuredClone(event);
    },

    handleFileinput() {
      this.$refs.addImage.selectFiles();
    },

    handleLocalImages() {
      this.localImages = structuredClone(this.images);
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
