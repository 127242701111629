<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <v-card class="pa-4">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">
          {{ productID ? "Editar produto" : "Criar produto" }}
        </div>

        <v-spacer />

        <v-btn color="primary" :disabled="loading" small icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-form v-model="valid" ref="form">
        <div class="mb-6" @keypress.enter.prevent="submit()">
          <ManageImages
            ref="manageImages"
            :images="form.images"
            @submit="handleChangedImages($event)"
          />

          <v-row>
            <v-col cols="12" class="py-0">
              <div class="text-body-2 mb-1">Nome</div>

              <v-text-field
                v-model="form.name"
                placeholder="Nome do produto"
                background-color="accent"
                :rules="_rules"
                solo
                flat
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <div class="text-body-2 mb-1">Valor mínimo para depósito</div>

              <v-text-field
                v-model="form.minDeposit"
                v-money="_money"
                id="minValue"
                placeholder="Valor mínimo para depósito"
                background-color="accent"
                :rules="_minValueRules"
                solo
                flat
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <div class="text-body-2 mb-1">Valor máximo para depósito</div>

              <v-text-field
                v-model="form.maxDeposit"
                v-money="_money"
                id="maxValue"
                placeholder="Valor máximo para depósito"
                background-color="accent"
                :rules="_maxValueRules"
                solo
                flat
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <div class="text-body-2 mb-1">Categoria</div>

              <v-select
                v-model="form.category"
                placeholder="Categoria"
                background-color="accent"
                :items="categories"
                :rules="_rules"
                item-text="text"
                item-value="value"
                solo
                flat
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <div class="text-body-2 mb-1">Quantidade de dias retidos</div>

              <v-text-field
                v-model="form.lockDays"
                placeholder="Quantidade de dias retidos"
                background-color="accent"
                :rules="_rules"
                solo
                flat
                @keypress="onlyNumber($event)"
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <div class="text-body-2 mb-1">Porcentagem de cotas vendida</div>

              <v-text-field
                v-model="form.sharesSold"
                placeholder="Porcentagem de cotas vendida"
                background-color="accent"
                :rules="_sharesRules"
                solo
                flat
                @keypress="onlyNumber($event)"
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <div class="text-body-2 mb-1">Descrição de tempo de contrato</div>

              <v-text-field
                v-model="form.timeDescription"
                placeholder="Descrição de tempo de contrato"
                background-color="accent"
                solo
                flat
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <div class="text-body-2 mb-1">Descrição de rendimento</div>

              <v-text-field
                v-model="form.yieldDescription"
                placeholder="Descrição de rendimento"
                background-color="accent"
                solo
                flat
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <div class="text-body-2 mb-1">Localização</div>

              <v-text-field
                v-model="form.location"
                placeholder="Localização/Local"
                background-color="accent"
                :rules="_rules"
                solo
                flat
              />
            </v-col>

            <v-col cols="12" class="py-0">
              <div class="text-body-2 mb-1">Objetivo</div>

              <v-textarea
                v-model="form.objective"
                placeholder="Objetivo"
                background-color="accent"
                auto-grow
                rows="3"
                solo
                flat
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <div class="text-body-2 mb-1">Politica operacional</div>

              <v-textarea
                v-model="form.operationalPolicy"
                placeholder="Politica operacional"
                background-color="accent"
                auto-grow
                rows="3"
                solo
                flat
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <div class="text-body-2 mb-1">Documento de contrato</div>

              <v-file-input
                v-model="form.contractDoc"
                placeholder="Documento de contrato"
                background-color="accent"
                :rules="_docRules"
                solo
                flat
              />
            </v-col>
          </v-row>

          <div class="d-flex align-center mb-1">
            <div class="text-body-1 primary--text font-weight-bold">
              Benefícios
            </div>

            <v-spacer />

            <v-btn class="text-none" color="primary" text @click="addBenefit()">
              Adicionar
            </v-btn>
          </div>

          <v-row
            v-for="(item, i) in form.benefits"
            class="mb-2"
            :key="i"
            no-gutters
          >
            <v-col cols="3" class="pr-2">
              <v-text-field
                v-model="form.benefits[i].icon"
                placeholder="Ícone"
                background-color="accent"
                hide-details="auto"
                solo
                flat
              />
            </v-col>

            <v-col cols="3" class="pr-2">
              <v-text-field
                v-model="form.benefits[i].text"
                placeholder="Texto"
                background-color="accent"
                :rules="_rules"
                hide-details="auto"
                solo
                flat
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="form.benefits[i].value"
                placeholder="Valor"
                background-color="accent"
                :rules="_rules"
                hide-details="auto"
                solo
                flat
              >
                <template v-slot:append>
                  <v-icon small @click="removeBenefit(i)">mdi-close</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <div
            v-if="!form.benefits.length"
            class="grey--text text-center text-body-2 mb-2"
          >
            Nenhum benefício adicionado
          </div>

          <v-tooltip v-if="form.benefits.length" bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                v-on="on"
                href="https://pictogrammers.com/library/mdi/"
                target="_blank"
              >
                Biblioteca de ícones
              </a>
            </template>

            <span>Copie o "icon name"</span>
          </v-tooltip>
        </div>

        <div class="d-flex">
          <v-spacer />

          <v-btn class="text-none rounded-lg" plain @click="close()">
            Cancelar
          </v-btn>

          <v-btn
            class="text-none rounded-lg"
            color="primary"
            :loading="loading"
            depressed
            @click="submit()"
          >
            {{ productID ? "Salvar alterações" : "Criar novo" }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { displayAlert, onlyNumber, parseNumber, currencyMask } from "@/utils";
import { VMoney } from "v-money";
import ManageImages from "./partials/manageImages/ManageImages.vue";

export default {
  name: "ManageNiche",

  components: {
    ManageImages,
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      productID: "",
      currentImages: [],
      categories: [],
      form: {
        images: [],
        name: "",
        minDeposit: "",
        maxDeposit: "",
        category: "",
        lockDays: "",
        sharesSold: "",
        timeDescription: "",
        yieldDescription: "",
        location: "",
        objective: "",
        operationalPolicy: "",
        contractDoc: null,
        benefits: [],
      },
    };
  },

  beforeMount() {
    this.getCategories();
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },

    _minValueRules() {
      return [(v) => parseNumber(v) >= 0 || "Valor mínimo de 0,00"];
    },

    _maxValueRules() {
      return [
        (v) =>
          parseNumber(v) >= parseNumber(this.form.minDeposit) ||
          "Valor mínimo de " + this.form.minDeposit,
      ];
    },

    _sharesRules() {
      return [
        (v) => v >= 0 || "Valor mínimo de 0",
        (v) => v <= 100 || "Valor máximo de 100",
      ];
    },

    _docRules() {
      return [(v) => !!this.productID || !!v || "Campo Obrigatório"];
    },

    _money() {
      return {
        decimal: ",",
        thousands: ".",
        precision: 2,
      };
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      if (!this.form.images.length)
        return this.displayAlert("Nenhuma imagem selecionada", 1);

      try {
        this.loading = true;

        let payload = {
          method: this.productID ? "updateContratoData" : "addNewContrato",
          nomeContrato: this.form.name,
          minDeposito: parseNumber(this.form.minDeposit),
          maxDeposito: parseNumber(this.form.maxDeposit),
          idCategoria: this.form.category,
          diasRetido: this.form.lockDays,
          cotaVendida: this.form.sharesSold,
          descTempodeContrato: this.form.timeDescription,
          descRendimentoContrato: this.form.yieldDescription,
          local: this.form.location,
          objetivo: this.form.objective,
          politicaOperacional: this.form.operationalPolicy,
          listaBeneficios: JSON.stringify(
            this.form.benefits.map((el) => JSON.stringify(el))
          ),
          ...(this.form.contractDoc && {
            docPDF: this.form.contractDoc,
          }),
          /* ...(this.form.images.length && {
            iconeContrato: this.form.images[0],
          }), */
          ...(this.productID && { contratoID: this.productID }),
          graficoCarteira: "",
          hexCorContrato: "",
        };

        this.form.images.forEach((el, i) => {
          payload[`imagens_${i}`] = el.file || el;
          payload[`imagens_${i}_isPath`] = el.file ? "0" : "1";
        });

        await request(payload);

        this.displayAlert(
          this.productID
            ? "Produto atualizado com sucesso"
            : "Produto criado com sucesso"
        );

        this.$emit("update");
        this.close();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getCategories() {
      try {
        let payload = {
          method: "listCategoriasAdmin",
        };

        const res = await request(payload);

        this.categories = res.categorias.map((el) => ({
          value: el.id,
          text: el.nome,
        }));
      } catch (err) {
        this.displayAlert(err, 1);
      }
    },

    handleChangedImages(event) {
      this.form.images = event;
    },

    addBenefit() {
      this.form.benefits.push({
        icon: "",
        text: "",
        value: "",
      });
    },

    removeBenefit(index) {
      this.form.benefits.splice(index, 1);
    },

    open(data) {
      this.productID = data?.id || "";
      this.currentImages = data?.images || [];

      this.form = {
        images: data?.images || [],
        name: data?.name || "",
        minDeposit: currencyMask(data?.minDeposit) || "",
        maxDeposit: currencyMask(data?.maxDeposit) || "",
        category: data?.categoryID || "",
        lockDays: data?.lockDays || "",
        sharesSold: data?.sharesSold || "",
        timeDescription: data?.timeDescription || "",
        yieldDescription: data?.yieldDescription || "",
        location: data?.location || "",
        objective: data?.objective || "",
        operationalPolicy: data?.operationalPolicy || "",
        contractDoc: data?.contractDoc || null,
        benefits:
          data?.benefits && data.benefits.filter((benefit) => !!benefit).length
            ? data.benefits.map((benefit) => JSON.parse(benefit))
            : [],
      };

      this.dialog = true;

      if (this.$refs.form) this.$refs.form.resetValidation();

      if (data.minDeposit && document.getElementById("minValue")) {
        document.getElementById("minValue").value = currencyMask(
          data?.minDeposit || 0
        );
      }

      if (data.maxDeposit && document.getElementById("maxValue")) {
        document.getElementById("maxValue").value = currencyMask(
          data?.maxDeposit || 0
        );
      }

      if (this.$refs.manageImages) this.$refs.manageImages.handleLocalImages();
    },

    close() {
      this.dialog = false;
    },

    displayAlert,

    onlyNumber,
  },
};
</script>
