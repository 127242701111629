<template>
  <div class="file-input-wrapper d-flex flex-column">
    <slot />

    <input
      ref="uploader"
      class="d-none"
      type="file"
      :maxlength="maxLength"
      :accept="accept"
      :multiple="multiple"
      @change="onFileChanged($event)"
    />

    <span v-if="!valid && required" class="required-message">
      {{ requiredMessage }}
    </span>
  </div>
</template>

<script>
export default {
  name: "FileInput",

  data() {
    return {
      valid: true,
      files: [],
    };
  },

  props: {
    accept: {
      type: String,
      default: "image/*,application/pdf",
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    maxLength: {
      type: String,
      default: "25000000", // 25mb
    },

    requiredMessage: {
      type: String,
      default: "Campo Obrigatório",
    },
  },

  methods: {
    submit() {
      this.$emit("input", this.multiple ? this.files : this.files[0]);
    },

    selectFiles() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },

    onFileChanged(event) {
      const files = Array.from(event.target.files);

      this.files = files.map((el) => ({
        file: el,
        objectURL: URL.createObjectURL(el),
      }));

      this.submit();
    },

    reset() {
      this.files = [];
      this.valid = true;

      this.submit();
    },

    validate() {
      this.valid = this.files.length;
      return this.valid;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
