<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans"> Gerenciar Produtos </span>
      </h1>

      <v-spacer />

      <v-btn
        color="primary"
        depressed
        rounded
        text
        @click="handleManageProduct()"
      >
        <v-icon small left> mdi-plus </v-icon>
        Novo Produto
      </v-btn>
    </div>

    <loader-content v-if="loading" />

    <section v-else>
      <v-row>
        <v-col
          v-for="(item, i) in products"
          class="zoom"
          cols="12"
          sm="6"
          md="4"
          xl="3"
          :key="i"
        >
          <router-link
            :to="`/products/details/${item.id}`"
            class="text-decoration-none"
          >
            <v-card class="shadow rounded-lg" height="100%" link>
              <div class="pa-4">
                <v-img
                  :src="$store.getters.apiURL + item.images[0]"
                  :aspect-ratio="16 / 9"
                  class="rounded-lg foreground mb-2"
                />

                <div class="d-flex flex-column pr-2">
                  <div class="d-flex">
                    <div class="primary--text text-h6 font-weight-bold">
                      {{ item.name }}
                    </div>

                    <v-spacer />

                    <v-btn
                      icon
                      small
                      @click.stop.prevent="handleManageProduct(item)"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </div>

                  <div class="text-body-2 subtext--text">
                    {{ item.yieldDescription }}
                  </div>

                  <div class="text-body-2 subtext--text">
                    {{ item.lockDays }} dias retidos / {{ item.sharesSold }}%
                    cotas vendidas
                  </div>
                </div>
              </div>
            </v-card>
          </router-link>
        </v-col>
      </v-row>

      <ManageProduct ref="manageProduct" @update="getContracts()" />
    </section>
  </div>
</template>

<script>
import { request } from "@/services";
import { currencyMask } from "@/utils";
import ManageProduct from "@/components/product/ManageProduct";

export default {
  components: {
    ManageProduct,
  },

  data() {
    return {
      loading: true,
      editDialog: false,
      products: [],
      selected: null,
    };
  },

  beforeMount() {
    this.getContracts();
  },

  methods: {
    async getContracts() {
      try {
        const payload = {
          method: "listContratos",
        };

        await request(payload).then(async (res) => {
          console.log(res);
          this.products = res.contratos.map((el) => ({
            id: el.id,
            icon: this.$store.getters.apiURL + el.iconeContrato,
            images: el.imagens,
            name: el.nomeContrato,
            timeDescription: el.descTempodeContrato,
            yieldDescription: el.descRendimentoContrato,
            lockDays: el.diasRetido,
            sharesSold: Number(el.cotaVendida),
            contractURl: this.$store.getters.apiURL + el.linkPDF,
            minDeposit: el.minDeposito,
            maxDeposit: el.maxDeposito,
            objective: el.objetivo,
            location: el.local,
            benefits: el.listaBeneficios,
            operationalPolicy: el.politicaOperacional,
            avaliable: el.avaliable === "1",
            categoryID: el.idCategoria,
          }));
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleManageProduct(data = null) {
      this.$refs.manageProduct.open(data);
    },

    currencyMask,
  },
};
</script>

<style></style>
